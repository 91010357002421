<template lang="pug">
div
	.widget
		.widget-header
			.row
				.col-sm-4
					h1.title Offers
				.col-sm-8
					.form-group
						select.form-control(v-model="selectedVersion")
							option(v-for="o in versionOptions", :value="o.v") {{ o.t }}

			h4 Request
			.row
				.col-sm-12
					.form-group
						label
						b-input-group
							b-form-input(:value="getOffersPath", readonly="readonly")
							b-input-group-append
								b-button(type="button", v-clipboard:copy="getOffersPath", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
									v-b-tooltip.hover.bottom, title="Copy to clipboard")
									i.la.la-copy

			.row
				.col-sm-12
					.form-group
						label API Key
						//span.form-control {{ USER.api_key }}
						b-input-group
							b-form-input(:value="USER.api_key", readonly="readonly")
							b-input-group-append
								b-button(type="button", v-clipboard:copy="USER.api_key", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
									v-b-tooltip.hover.bottom, title="Copy to clipboard")
									i.la.la-copy
			.ov-table-wrapper
				table.table.table-bordered
					thead
						tr
							th Param
							th Description
							th &nbsp;
					tbody
						tr(v-for="r in getOffersQueryParams", v-bind:key="r.name")
							td
								b-badge(variant="dark") {{ r.name }}
							td {{ r.desc }}
							td
								b-badge(variant="warning", v-if="r.required") Required

			p &nbsp;
			hr
			p &nbsp;

			h4 Response
			json-snippet(v-if="selectedVersion == 'v2'", :json-data="getOffersResponseObj", title="Response")

			div(v-if="selectedVersion !== 'v2'")
				param-table(:rows="getOffersRespParams", @format-clicked="formatInfo")
				json-snippet(:json-data="getOffersResponseObj", title="Response Example")

				h5.mt-4(ref="offer-table")
					b-badge.format-badge(:variant="formatVariant('Offer')") Offer:
				param-table(:rows="offerParams", @format-clicked="formatInfo")
				json-snippet(:json-data="offerObj", title="Offer Example")

				h5.mt-4(ref="event-table")
					b-badge.format-badge(:variant="formatVariant('Event')") Event:
				param-table(:rows="eventParams", @format-clicked="formatInfo")
				json-snippet(:json-data="eventObj", title="Event Example")

				h5.mt-4(ref="targeting-table")
					b-badge.format-badge(:variant="formatVariant('Targeting')") Targeting:
				param-table(:rows="targetingParams", @format-clicked="formatInfo")
				json-snippet(:json-data="targetingObj", title="Targeting Example")

				h5.mt-4(ref="country-table")
					b-badge.format-badge(:variant="formatVariant('Country')") Country:
				param-table(:rows="countryParams", @format-clicked="formatInfo")

				h5.mt-4(ref="region-table")
					b-badge.format-badge(:variant="formatVariant('Region')") Region:
				param-table(:rows="regionParams", @format-clicked="formatInfo")

				h5.mt-4(ref="city-table")
					b-badge.format-badge(:variant="formatVariant('City')") City:
				param-table(:rows="cityParams", @format-clicked="formatInfo")

				h5.mt-4(ref="wlbl-table")
					b-badge.format-badge(:variant="formatVariant('WhiteList/BlackList')") WhiteList/BlackList:
				param-table(:rows="wlblParams", @format-clicked="formatInfo")

				h5.mt-4(ref="creative-table")
					b-badge.format-badge(:variant="formatVariant('Creative')") Creative:
				param-table(:rows="creativeParams", @format-clicked="formatInfo")

				h5.mt-4(ref="pacing-table")
					b-badge.format-badge(:variant="formatVariant('Pacing')") Pacing:
				param-table(:rows="pacingParams", @format-clicked="formatInfo")

				h5.mt-4(ref="timeframe-table")
					b-badge.format-badge(:variant="formatVariant('Timeframe')") Timeframe:
				param-table(:rows="timeframeParams", @format-clicked="formatInfo")

			h5.mt-4 Click URL parameters:
			.ov-table-wrapper
				table.table.table-bordered
					thead
						tr
							th Param
							th Description
							th &nbsp;
					tbody
						tr(v-for="r in clickParams", v-bind:key="r.name")
							td
								h6
									b-badge(variant="info") {{ r.name }}
							td {{ r.desc }}
							td
								b-badge(variant="warning", v-if="r.required") Required






	.widget(v-if="USER.features.apiStatsEnabled")
		.widget-header
			h1.title Statistics
			h4 Request
			.row
				.col-sm-12
					.form-group
						label
						b-input-group
							b-form-input(:value="getStatisticsPath", readonly="readonly")
							b-input-group-append
								b-button(type="button", v-clipboard:copy="getStatisticsPath", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
									v-b-tooltip.hover.bottom, title="Copy to clipboard")
									i.la.la-copy

			.row
				.col-sm-12
					.form-group
						label API Key
						b-input-group
							b-form-input(:value="USER.api_key", readonly="readonly")
							b-input-group-append
								b-button(type="button", v-clipboard:copy="USER.api_key", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
									v-b-tooltip.hover.bottom, title="Copy to clipboard")
									i.la.la-copy
			.ov-table-wrapper
				table.table.table-bordered
					thead
						tr
							th Param
							th Description
							th &nbsp;
					tbody
						tr(v-for="r in getStatisticsQueryParams", v-bind:key="r.name")
							td
								b-badge(variant="dark") {{ r.name }}
							td {{ r.desc }}
							td
								b-badge(variant="warning", v-if="r.required") Required

			p &nbsp;
			hr
			p &nbsp;

			h4 Response
			div
				param-table(:rows="getStatisticsRespParams", @format-clicked="formatInfo")
				json-snippet(:json-data="getStatisticsResponseObj", title="Response Example")

				h5.mt-4(ref="offer-stats-table")
					b-badge.format-badge(:variant="formatVariant('OfferStats')") Offer Stats:
				param-table(:rows="offerStatsParams", @format-clicked="formatInfo")
				json-snippet(:json-data="offerStatsObj", title="Offer Stats Example")

				h5.mt-4(ref="offer-stats-summary-table")
					b-badge.format-badge(:variant="formatVariant('OfferStats')") Offer Stats Summary:
				param-table(:rows="offerStatsSummaryParams", @format-clicked="formatInfo")
				json-snippet(:json-data="offerStatsSummaryObj", title="Offer Stats Summary Example")


			//div
				p &nbsp;
				hr
				p &nbsp;

				h4 Affise
				p If you wish to scan the offers using affise, use {{ USER.offerwallPath }} as base, and the API Key as token

</template>

<script>
import moment from 'moment';
import ParamTable from "../components/ParamTable";
import JsonSnippet from "../components/JsonSnippet";
let trackBase = 'https://track.base.url';

export default {
	name: 'API',
	components: {
		ParamTable,
		JsonSnippet
	},
	computed: {
		USER() {
			return this.$store.state.user;
		},
		getOffersPath() {
			const basePath = this.$store.state.user.offerwallPath || '';
			switch (this.selectedVersion) {
				case "v2":
					return basePath + 'api/v2.0/getOffers?' + this.getOffersQueryParams.map(p => `${p.name}=${p.val}`).join('&');
				case "v3":
					return basePath + 'api/v3.0/getOffers?' + this.getOffersQueryParams.map(p => `${p.name}=${p.val}`).join('&');
				default:
					return '';
			}
		},
		getStatisticsPath() {
			const basePath = this.$store.state.user.offerwallPath || '';
			return basePath + 'api/v3.0/getStats?' + this.getStatisticsQueryParams.map(p => `${p.name}=${p.val}`).join('&');
		},
		getOffersQueryParams() {
			switch (this.selectedVersion) {
				case "v2":
				case "v3":
					return [
						{ name: 'api_key', val: this.USER.api_key, desc: 'Your private API key', required: true },
						{ name: 'page', val: 1, desc: 'Page number (1 +)' },
						{ name: 'limit', val: 50, desc: 'N umber of results per page' },
					];
				default:
					return [];
			}
		},
		getStatisticsQueryParams() {
			const dateStart = encodeURIComponent(moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'));
			const dateEnd = encodeURIComponent(moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'));
			return [
				{ name: 'api_key', val: this.USER.api_key, desc: 'Your private API key', required: true },
				{ name: 'page', val: 1, desc: 'Page number (1 +)' },
				{ name: 'limit', val: 50, desc: 'N umber of results per page' },
				{ name: 'date_start', val: dateStart, desc: 'Start date' },
				{ name: 'date_end', val: dateEnd, desc: 'End date' },
				{ name: 'offer_ids', val: 1, desc: 'Offer IDs. Should be used like offer_ids=1&offer_ids=2' },
				// {name: 'os', val: 'ios', desc: 'Selected OS. Values - ios, android, other'}, 
				{ name: 'countries', val: 'us', desc: 'Selected countries. Values - us, gb, de, fr, etc.' },
				{ name: 'timezone', val: 0, desc: 'Timezone offset in hours' },
			];
		},
		targetingObj() {
			return {
				"whitelist": [{
					"id": 840, "code2": "us", "code3": "usa", "name": "united states", "continent_code": 6, "timezone": "", "regions": [
						{ "id": 6, "country_id": 840, "country_code2": "us", "country_code3": "usa", "region_code": "co", "name": "colorado" }
					],
					"cities": []
				}],
				"blacklist": []
			}
		},
		getOffersResponseObj() {
			switch (this.selectedVersion) {
				case "v2":
					return {
						errcode: 0,
						errdesc: null,
						offers: [
							{
								'id': 12543,
								'payout': 123,
								'payout_type': 'CPA',
								'country': 'GB',
								'platform': 'ios',
								'preview_url': 'https://itunes.apple.com/GB/app/id586196841?mt=8',
								'icon': 'https://is3-ssl.mzstatic.com/image/thumb/Purple128/v4/3d/28/de/3d28de08-0d87-fa17-1e68-a62225875fe9/source/512x512bb.jpg',
								'bundle_id': 'com.rl.iphone.dino',
								"package_id": "123123123",
								'name': 'Casino - GB - ios - CPA',
								'currency': 'USD',
								'daily_cap': 0,
								'hard_kpi': false,
								'description': '- Vertical: Casino\n- Pricing model: CPA (deposit)\n- Flow: Download App -> REG -> Deposit.',
								"short_description": 'Vertical: Casino\n- Pricing model: CPA (deposit)',
								'kpi': 'None',
								'click_url': trackBase + 'o/XXXX?p=PUBLISHER_ID',
								'impression_url': trackBase + 'i/XXXX?p=PUBLISHER_ID',
								"events": [
									{ "type": 1, "name": "registration" },
									{ "type": 2, "name": "ftd" }
								],
								"targeting": {
									"countries": {
										"whitelist": [],
										"blacklist": []
									}
								},
								"kpi_value": 0,
								"event_managed": false,
								"restriction_list": [],
								"allow_incent": 0,
								"default_placement_payout": 3,
								"daily_click_cap": 2000000,
								"white_list": [],
								"black_list": [],
								"creatives": [],
								"remaining_conversion_cap": 40
							}
						],
						total: 1,
						num_pages: 1,
						page: 1,
						limit: 50
					};
				case "v3":
					return {
						"errcode": 0,
						"errdesc": null,
						"offers": [this.offerObj],
						"total": 1,
						"num_pages": 1,
						"page": 1,
						"limit": 50
					}
				default:
					return {};
			}
		},
		getStatisticsResponseObj() {
			return {
				"errcode": 0,
				"errdesc": null,
				"stats": [this.offerStatsObj],
				"total": 1,
				"num_pages": 1,
				"page": 1,
				"limit": 50,
				"summary": this.offerStatsSummaryObj
			};
		},
		offerStatsObj() {
			return {
				"clicks": 100,
				"impressions": 0,
				"conversions": 0,
				"installs": 0,
				"revenue": 0,
				"offer_id": 1,
				"cr": 0,
				"epc": 0,
				"offer_name": "Test Offer"
			};
		},
		getOffersRespParams() {
			switch (this.selectedVersion) {
				case "v2":
					return []
				case "v3":
					return [
						{ key: 'offers', format: 'Offer', desc: '', array: true },
						{ key: 'total', format: 'Integer', desc: 'Total number of offers' },
						{ key: 'num_pages', format: 'Integer', desc: 'Total number of pages' },
						{ key: 'page', format: 'Integer', desc: 'Current page number' },
						{ key: 'limit', format: 'Integer', desc: 'Number of results per page' },
						{ key: 'errcode', format: 'Integer', desc: '0 for no errors' },
						{ key: 'errdesc', format: 'String', desc: 'Error description' }
					];
				default:
					return [];
			}
		},
		getStatisticsRespParams() {
			return [
				{ key: 'stats', format: 'OfferStats', desc: '', array: true },
				{ key: 'total', format: 'Integer', desc: 'Total number of offers' },
				{ key: 'num_pages', format: 'Integer', desc: 'Total number of pages' },
				{ key: 'page', format: 'Integer', desc: 'Current page number' },
				{ key: 'limit', format: 'Integer', desc: 'Number of results per page' },
				{ key: 'errcode', format: 'Integer', desc: '0 for no errors' },
				{ key: 'errdesc', format: 'String', desc: 'Error description' },
				{ key: 'summary', format: 'OfferStatsSummary', desc: 'Summary of the stats' },
			];
		},
		offerObj() {
			return {
				"id": 646494,
				"category": "",
				"events": [
					{ "type": 1, "name": "registration", "payable": false },
					{ "type": 2, "name": "ftd", "payable": true }
				],
				"targeting": this.targetingObj,
				"restriction_list": ["Invalid or duplicate leads unaccepted", "No Hard-Incentivized"],
				"allow_incent": false,
				"payout": 0.6,
				"payout_type": "CPA",
				"country": "US",
				"platform": "ios",
				"preview_url": "https://apps.apple.com/US/app/id878577184?mt=8",
				"icon": "https://is3-ssl.mzstatic.com/image/thumb/Purple128/v4/3d/28/de/3d28de08-0d87-fa17-1e68-a62225875fe9/source/512x512bb.jpg",
				"name": "SheIn US iOS",
				"currency": "USD",
				"sub1": { "white_list": [], "black_list": ["213_45", "111780", "188"] },
				"sub2": { "white_list": [], "black_list": [] },
				"daily_conversion_cap": 24,
				"daily_click_cap": 100,
				"daily_impression_cap": 0,
				"short_description": "...",
				"description": "....",
				"user_flow": "Download the App. Install the App.",
				"creatives": [],
				"remaining_conversion_cap": 24,
				"click_url": trackBase + "/o/123?p=456",
				"impression_url": trackBase + "/i/123?p=456",
				"ip": { "white_list": [], "black_list": ['1.2.3.4', '5.6.7.8'] },
				"click_pacing": { "hour": 0, "minute": 0 },
				"impression_pacing": { "hour": 0, "minute": 0 },
				"restrict_timeframe": true,
				"restrict_timeframe_schedule": [
					{
						"d": "mon",
						"hr": [
							false,
							false,
							false,
							false,
							false,
							true,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false
						]
					},
					{
						"d": "tue",
						"hr": [
							false,
							false,
							false,
							false,
							true,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false
						]
					},
					{
						"d": "wed",
						"hr": [
							false,
							false,
							false,
							true,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false
						]
					},
					{
						"d": "thu",
						"hr": [
							false,
							false,
							true,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false
						]
					},
					{
						"d": "fri",
						"hr": [
							false,
							true,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false
						]
					},
					{
						"d": "sat",
						"hr": [
							true,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false
						]
					},
					{
						"d": "sun",
						"hr": [
							false,
							false,
							false,
							false,
							false,
							false,
							true,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false,
							false
						],
					}
				],
				"restrict_timeframe_timezone": 0,
				"mmp_type": 'appsflyer',
			}
		},
		offerStatsSummaryObj() {
			return {
				"impressions": 0,
				"clicks": 100,
				"conversions": 0,
				"installs": 0,
				"revenue": 0,
				"cr": 0,
				"epc": 0
			};
		},
		eventObj() {
			return { "type": 1, "name": "ftd", "payable": true };
		},
		offerParams() {
			switch (this.selectedVersion) {
				case "v3":
					return [
						{ key: 'id', format: 'Integer', desc: 'Offer ID' },
						{ key: 'category', format: 'String', desc: 'PlayStore/AppStore Category' },
						{ key: 'events', format: 'Event', desc: 'AKA Goals', array: true },
						{ key: 'targeting', format: 'Targeting', desc: 'Geo Targeting Whitelist & Blacklist' },
						{ key: 'restriction_list', format: 'String', desc: '', array: true },
						{ key: 'allow_incent', format: 'Boolean', desc: '' },
						{ key: 'payout', format: 'Integer', desc: 'Offer Payout' },
						{ key: 'payout_type', format: 'String', values: ['CPA', 'CPI', 'CPR', 'CPC', 'CPM', 'CPS', 'CPE', 'CPL'], desc: 'Offer Payout Model' },
						{ key: 'country', format: 'String', desc: 'ISO 3166-1 separated by commas' },
						{ key: 'platform', format: 'String', values: ['ios', 'android', 'desktop', 'all'], desc: 'OS' },
						{ key: 'preview_url', format: 'String', desc: 'Preview Link' },
						{ key: 'icon', format: 'String', desc: 'A URL of the App\'s icon' },
						{ key: 'name', format: 'String', desc: 'Offer Name' },
						{ key: 'currency', format: 'String', desc: 'Currency Code' },
						{ key: 'sub1', format: 'WhiteList/BlackList', desc: 'Subsources Whitelist & Blacklist' },
						{ key: 'sub2', format: 'WhiteList/BlackList', desc: 'Sub-Subsources Whitelist & Blacklist' },
						{ key: 'daily_conversion_cap', format: 'Integer', desc: 'Daily Conversion Cap' },
						{ key: 'remaining_conversion_cap', format: 'Integer', desc: 'Remaining Daily Conversion Cap' },
						{ key: 'daily_click_cap', format: 'Integer', desc: 'Daily Click Cap' },
						{ key: 'daily_impression_cap', format: 'Integer', desc: 'Daily Impression Cap' },
						{ key: 'description', format: 'String', desc: 'Description of the app' },
						{ key: 'short_description', format: 'String', desc: 'The shortened version of the description' },
						{ key: 'user_flow', format: 'String', desc: '' },
						{ key: 'click_url', format: 'String', desc: 'Click Tracking Link' },
						{ key: 'impression_url', format: 'String', desc: 'Impression Tracking Link' },
						{ key: 'ip', format: 'WhiteList/BlackList', desc: 'IP Addresses Whitelist & Blacklist' },
						{ key: 'creatives', format: 'Creative', desc: 'Creatives', array: true },
						{ key: 'bundle_id', format: 'String', desc: 'Appstore / Google play unique identifier' },
						{ key: 'package_id', format: 'String', desc: '' },
						{ key: 'click_pacing', format: 'Pacing', desc: 'Limit of clicks per timeframe' },
						{ key: 'impression_pacing', format: 'Pacing', desc: 'Limit of impressions per timeframe' },
						{ key: 'restrict_timeframe', format: 'Boolean', desc: 'Restrict by timeframe' },
						{ key: 'restrict_timeframe_schedule', format: 'Timeframe', array: true, desc: 'Restrict by timeframe schedule. Will be an empty array if restrict_timeframe is false' },
						{ key: 'restrict_timeframe_timezone', format: 'Integer', desc: 'Timezone offset' },
						{
							key: 'mmp_type',
							format: 'String',
							desc: "Type of the offer's MMP",
							values: [
								'appsflyer',
								'kochava',
								'branch',
								'adjust',
								'tenjin',
								'singular',
								'tune',
							],
						},
					];
				default:
					return [];
			}
		},
		offerStatsParams() {
			return [
				{ key: 'clicks', format: 'Integer', desc: 'Number of clicks' },
				{ key: 'impressions', format: 'Integer', desc: 'Number of impressions' },
				{ key: 'conversions', format: 'Integer', desc: 'Number of conversions' },
				{ key: 'installs', format: 'Integer', desc: 'Number of installs' },
				{ key: 'revenue', format: 'Float', desc: 'Revenue' },
				{ key: 'offer_id', format: 'Integer', desc: 'Offer ID' },
				{ key: 'offer_name', format: 'String', desc: 'Entity Name' },
				{ key: 'cr', format: 'Float', desc: 'Conversion Rate' },
				{ key: 'epc', format: 'Float', desc: 'Earnings Per Click' },
			];
		},
		offerStatsSummaryParams() {
			return [
				{ key: 'impressions', format: 'Integer', desc: 'Number of impressions' },
				{ key: 'clicks', format: 'Integer', desc: 'Number of clicks' },
				{ key: 'conversions', format: 'Integer', desc: 'Number of conversions' },
				{ key: 'installs', format: 'Integer', desc: 'Number of installs' },
				{ key: 'revenue', format: 'Float', desc: 'Revenue' },
				{ key: 'cr', format: 'Float', desc: 'Conversion Rate' },
				{ key: 'epc', format: 'Float', desc: 'Earnings Per Click' },
			];
		},
		eventParams() {
			switch (this.selectedVersion) {
				case "v2":
				case "v3":
					return [
						{ key: 'type', format: 'Integer', desc: 'Event Index' },
						{ key: 'name', format: 'String', desc: 'Event Name' },
						{ key: 'payable', format: 'Boolean', desc: 'Is the event payable' },
					];
				default:
					return [];
			}
		},
		targetingParams() {
			switch (this.selectedVersion) {
				case "v3":
					return [
						{ key: 'whitelist', format: 'Country', desc: 'Targeted GEO', array: true },
						{ key: 'blacklist', format: 'Country', desc: 'Restricted GEO', array: true },
					];
				default:
					return [];
			}
		},
		countryParams() {
			switch (this.selectedVersion) {
				case "v3":
					return [
						{ key: 'id', format: 'Integer', desc: '' },
						{ key: 'code2', format: 'String', desc: '' },
						{ key: 'code3', format: 'String', desc: '' },
						{ key: 'name', format: 'String', desc: '' },
						{ key: 'continent_code', format: 'Integer', desc: '' },
						{ key: 'timezone', format: 'String', desc: '' },
						{ key: 'regions', format: 'Region', desc: '', array: true },
						{ key: 'cities', format: 'City', desc: '', array: true },
					];
				default:
					return [];
			}
		},
		regionParams() {
			switch (this.selectedVersion) {
				case "v3":
					return [
						{ key: 'id', format: 'Integer', desc: '' },
						{ key: 'country_id', format: 'Integer', desc: '' },
						{ key: 'country_code2', format: 'String', desc: '' },
						{ key: 'country_code3', format: 'String', desc: '' },
						{ key: 'name', format: 'String', desc: '' },
						{ key: 'region_code', format: 'String', desc: '' }
					];
				default:
					return [];
			}
		},
		cityParams() {
			switch (this.selectedVersion) {
				case "v3":
					return [
						{ key: 'id', format: 'Integer', desc: '' },
						{ key: 'country_id', format: 'Integer', desc: '' },
						{ key: 'country_code2', format: 'String', desc: '' },
						{ key: 'country_code3', format: 'String', desc: '' },
						{ key: 'name', format: 'String', desc: '' },
						{ key: 'city_code', format: 'String', desc: '' }
					];
				default:
					return [];
			}
		},
		creativeParams() {
			switch (this.selectedVersion) {
				case "v3":
					return [
						{ key: 'id', format: 'Integer', desc: '' },
						{ key: 'type', format: 'String', desc: '' },
						{ key: 'width', format: 'Integer', desc: '' },
						{ key: 'height', format: 'Integer', desc: '' },
						{ key: 'duration', format: 'Integer', desc: '' },
						{ key: 'url', format: 'String', desc: '' },
					];
				default:
					return [];
			}
		},
		pacingParams() {
			switch (this.selectedVersion) {
				case "v3":
					return [
						{ key: 'hour', format: 'Integer', desc: 'Acceptable amount during 1 hour.\n0 = no limit' },
						{ key: 'minute', format: 'Integer', desc: 'Acceptable amount during 1 minute.\n0 = no limit' },
					];
				default:
					return [];
			}
		},
		timeframeParams() {
			switch (this.selectedVersion) {
				case "v3":
					return [
						{ key: 'd', format: 'String', values: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'], desc: 'Day of the week' },
						{ key: 'hr', format: 'Boolean', desc: 'Hourly timeframe array for the whole day from 0 to 23.', array: true },
					];
				default:
					return [];
			}
		},
		wlblParams() {
			switch (this.selectedVersion) {
				case "v3":
					return [
						{ key: 'whitelist', format: 'String', desc: 'A list of the ONLY valid values', array: true },
						{ key: 'blacklist', format: 'String', desc: 'A list of the restricted values', array: true },
					];
				default:
					return [];
			}
		},
		clickParams() {
			return [
				{ name: '{aff_clickid}', placeholder: '', desc: 'Publisher unique clickId - will be fired back in the postback' },
				{ name: '{sub1}', placeholder: '', desc: 'primary subsource' },
				{ name: '{sub2}', placeholder: '', desc: 'secondary subsource' },
				{ name: '{sub3}', placeholder: '', desc: '...' },
				{ name: '{idfa}', placeholder: '', desc: 'IDFA' },
				{ name: '{gaid}', placeholder: '', desc: 'Google Advertising ID' },
				{ name: '{device_id}', placeholder: '', desc: 'Generic variable for {idfa}/{gaid}' },
				{ name: '{app_name}', placeholder: '', desc: 'Name of the source mobile app' },
				{ name: '{bundle_id}', placeholder: '', desc: 'Source app bundle' },
			];
		},
	},
	data() {
		return {
			publisherAPIBase: process.env.VUE_APP_API_BASE_URL,
			trackBase: trackBase,
			versionOptions: [
				{ v: 'v2', t: 'API V2' },
				{ v: 'v3', t: 'API V3' }
			],
			selectedVersion: 'v3',
			expandedResp: false,
		};
	},
	methods: {
		formatInfo(format) {
			switch (format) {
				case 'Event':
					this.$refs['event-table'].scrollIntoView({ behavior: "smooth" });
					break;
				case 'Offer':
					this.$refs['offer-table'].scrollIntoView({ behavior: "smooth" });
					break;
				case 'WhiteList/BlackList':
					this.$refs['wlbl-table'].scrollIntoView({ behavior: "smooth" });
					break;
				case 'Pacing':
					this.$refs['pacing-table'].scrollIntoView({ behavior: "smooth" });
					break;
				case 'Timeframe':
					this.$refs['timeframe-table'].scrollIntoView({ behavior: "smooth" });
					break;
				case 'Creative':
					this.$refs['creative-table'].scrollIntoView({ behavior: "smooth" });
					break;
				case 'Targeting':
					this.$refs['targeting-table'].scrollIntoView({ behavior: "smooth" });
					break;
				case 'Country':
					this.$refs['country-table'].scrollIntoView({ behavior: "smooth" });
					break;
				case 'Region':
					this.$refs['region-table'].scrollIntoView({ behavior: "smooth" });
					break;
				case 'City':
					this.$refs['city-table'].scrollIntoView({ behavior: "smooth" });
					break;

				case 'OfferStats':
					this.$refs['offer-stats-table'].scrollIntoView({ behavior: "smooth" });
					break;
				case 'OfferStatsSummary':
					this.$refs['offer-stats-summary-table'].scrollIntoView({ behavior: "smooth" });
					break;

				default:
				// no-op
			}
		},

		onCopySuccess() {
			this.$ovNotify.success('Copied to clipboard');
		},

		onCopyError() {
			this.$ovNotify.error('Could not copy text');
		},

		formatVariant(format) {
			switch (format) {
				case 'Offer':
					return 'primary';
				case 'Event':
					return 'info';
				case 'WhiteList/BlackList':
					return 'dark';
				case 'Creative':
					return 'warning';
				case 'Pacing':
				case 'Timeframe':
					return 'danger';

				case 'Targeting':
					return 'success';
				case 'Country':
				case 'Region':
				case 'City':
					return 'secondary';

				default:
					return 'light';
			}
		},

	}
}
</script>
