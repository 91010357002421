<template lang="pug">
	div
		h6(v-if="title") {{ title }}
			a(href="javascript: void(0);", @click="expanded = !expanded", v-b-tooltip.hover, :title="expanded ? 'Minimize' : 'Show More'")
				i.la(:class="{'la-chevron-right': !expanded, 'la-chevron-down': expanded}")
		pre(v-if="!expanded", @click="expanded = true")
			span { ...

		pre(v-if="expanded")
			vue-json-pretty(v-if="expanded" :data="jsonData")
</template>
<script>
	import VueJsonPretty from "vue-json-pretty";
	import 'vue-json-pretty/lib/styles.css';

	export default {
		name: 'json-snippet',
		props: {
			jsonData: {
				type: Object,
				required: true,
			},
			title: {
				type: String,
				required: false
			}
		},
		components: {
			VueJsonPretty,
		},
		data(){
			return {
				expanded: false
			}
		},
		methods: {

		},

	}
</script>
